import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  CardMedia,
} from "@mui/material";
import { toast } from "react-toastify";
import { Transition } from "../../Constants/Constant";
import { MdOutlineCancel } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import AxiosClient from "../../Helpers/axios";
import { IoMdSave } from "react-icons/io";
import { IoCloudUpload } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const AddCategory = ({ refreshCategories }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [thumbnailId, setThumbnailId] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [productInfo, setProductInfo] = useState({
    name: "",
    price: "",
    category: "",
    description: "",
  });

  const handleOnchange = (e) => {
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = async (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileType", "category");

    try {
      const { data } = await AxiosClient.post("api/file", formData);
      setThumbnailId(data.fileKey);
      toast.success("Picture uploaded successfully", {
        autoClose: 500,
        theme: "colored",
      });
      setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      toast.error("Picture upload failed", {
        autoClose: 500,
        theme: "colored",
      });
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!productInfo.name || !thumbnailId) {
      toast.error("Please fill the all fields", {
        autoClose: 500,
        theme: "colored",
      });
      return;
    }
    setLoading(true);

    try {
      await AxiosClient.post("api/admin/category", {
        name: productInfo.name,
        image: thumbnailId,
      });
      setOpen(false);

      toast.success("Product added successfully", {
        autoClose: 500,
        theme: "colored",
      });
      setProductInfo({
        name: "",
        image: "",
        price: "",
        category: "",
        description: "",
      });
      setThumbnailUrl(null);
      refreshCategories();
      navigate(`/admin/home?tab=category`);
    } catch (error) {
      toast.error("Something went wrong", {
        autoClose: 500,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "right",
          margin: "20px 0",
        }}
      >
        <Button
          variant="contained"
          endIcon={<FaPlus />}
          onClick={handleClickOpen}
        >
          Create
        </Button>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", color: "#1976d2" }}
        >
          {" "}
          Create category
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    name="name"
                    value={productInfo.name}
                    onChange={handleOnchange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  {thumbnailUrl ? (
                    <CardMedia
                      component="img"
                      image={thumbnailUrl}
                      alt="Picture"
                      style={{ maxHeight: "300px", margin: "10px 0px" }}
                    />
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    disabled={uploading}
                  >
                    {uploading ? "Uploading..." : "Upload picture"}
                    <IoCloudUpload />
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleFileUpload}
                    />
                  </Button>
                </Grid>
              </Grid>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  type="reset"
                  color="error"
                  onClick={handleClose}
                  endIcon={<MdOutlineCancel />}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  endIcon={<IoMdSave />}
                  disabled={loading}
                >
                  {loading ? "Creating..." : "Create"}
                </Button>
              </DialogActions>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCategory;
