import { Container } from "@mui/system";
import OrderCard from "../../Components/Card/OrderCard/OrderCard";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { AiFillCloseCircle, AiOutlineLogin } from "react-icons/ai";
import { EmptyCart } from "../../Assets/Images/Image";
import { Transition } from "../../Constants/Constant";
import CopyRight from "../../Components/CopyRight/CopyRight";
import AxiosClient from "../../Helpers/axios";
import { FaTelegram } from "react-icons/fa";

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  let authToken = localStorage.getItem("Authorization");
  let setProceed = authToken ? true : false;
  let navigate = useNavigate();
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    if (setProceed) {
      const { data } = await AxiosClient.get("/api/order");
      setOrders(data.orders);
    } else {
      setOpenAlert(true);
    }
  };

  const handleClose = () => {
    setOpenAlert(false);
    navigate("/");
  };
  const handleToLogin = () => {
    navigate("/login");
  };

  const handleTelegramClick = () => {
    window.open("https://t.me/huangwanli", "_blank");
  };

  const buttonStyle = {
    color: "#1976d2",
  };

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          margin: "10px 0 ",
          color: "#1976d2",
          fontWeight: "bold",
        }}
      >
        Buyurtmalar
      </Typography>
      {setProceed && orders.length <= 0 ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="main-card">
            <img src={EmptyCart} alt="Empty_cart" className="empty-cart-img" />
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: "#1976d2", fontWeight: "bold" }}
            >
              Buyurtmalar ro'yhati bo'sht
            </Typography>
          </div>
        </Box>
      ) : (
        <Container
          style={{
            paddingBottom: 20,
          }}
        >
          <div>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "#1976d2",
                fontWeight: "bold",
              }}
            >
              Buyurtmaga to'lov qilish uchun biz bilan bog'laning:
            </Typography>
          </div>
          <div>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                color: "#1976d2",
                fontWeight: "bold",
              }}
            >
              Telefon:{" "}
              <a href="tel:+998889976666" style={buttonStyle}>
                +998(88) 997 6666
              </a>
            </Typography>
          </div>
          <Box sx={{ textAlign: "center", marginTop: 2 }}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleTelegramClick}
                startIcon={<FaTelegram />}
              >
                huangwanli
              </Button>
            </div>
          </Box>

          {orders.map((order) => (
            <OrderCard key={order._id} order={order} />
          ))}
        </Container>
      )}

      <Dialog
        open={openAlert}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            width: { xs: 280, md: 350, xl: 400 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">
            Davom ettirish uchun sahifangizga kiring
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            variant="contained"
            onClick={handleToLogin}
            endIcon={<AiOutlineLogin />}
            color="primary"
          >
            Kirish
          </Button>
          <Button
            variant="contained"
            color="error"
            endIcon={<AiFillCloseCircle />}
            onClick={handleClose}
          >
            Ortga
          </Button>
        </DialogActions>
      </Dialog>
      <CopyRight sx={{ mb: 10 }} />
    </>
  );
};

export default Order;
