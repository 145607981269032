import "./Desktop.css";
import React, { useContext, useEffect } from "react";
import { AiOutlineHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { Link, NavLink } from "react-router-dom";
import { Badge, Tooltip } from "@mui/material";
import { ContextFunction } from "../Context/Context";
import { getCart, getWishList } from "../Constants/Constant";
import { TbTruckDelivery } from "react-icons/tb";

const DesktopNavigation = () => {
  const { cart, setCart, wishlistData, setWishlistData } =
    useContext(ContextFunction);
  let authToken = localStorage.getItem("Authorization");
  let setProceed = authToken !== null ? true : false;
  useEffect(() => {
    getCart(setProceed, setCart, authToken);
    getWishList(setProceed, setWishlistData, authToken);
  }, []);

  return (
    <>
      <nav className="nav">
        <div className="logo">
          <Link to="/">
            <span>LuxBags</span>
          </Link>
        </div>
        <div className="nav-items">
          <ul className="nav-items">
            <li className="nav-links">
              <NavLink to="/">
                <span className="nav-icon-span">Bosh sahifa</span>
              </NavLink>
            </li>
            <li className="nav-links">
              <Tooltip title="Savatcha">
                <NavLink to="/cart">
                  <span className="nav-icon-span">
                    Savatcha{" "}
                    <Badge badgeContent={setProceed ? cart.length : 0}>
                      {" "}
                      <AiOutlineShoppingCart className="nav-icon" />
                    </Badge>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-links">
              <Tooltip title="Sevimli">
                <NavLink to="/wishlist">
                  <span className="nav-icon-span">
                    Sevimli{" "}
                    <Badge badgeContent={setProceed ? wishlistData.length : 0}>
                      {" "}
                      <AiOutlineHeart className="nav-icon" />
                    </Badge>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-links">
              <Tooltip title="Buyurtma">
                <NavLink to="/order">
                  <span className="nav-icon-span">
                    Buyurtma{" "}
                    <Badge>
                      <TbTruckDelivery className="nav-icon" />
                    </Badge>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
            <>
              <li className="nav-links">
                <Tooltip title="Shaxsiy sahifa">
                  <NavLink to="/profile">
                    <span className="nav-icon-span">
                      {" "}
                      <CgProfile
                        style={{
                          fontSize: 29,
                          marginTop: 7,
                          marginRight: 10,
                        }}
                      />
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            </>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default DesktopNavigation;
