import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, Typography, Box, useMediaQuery, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import UserTable from "./Tables/UserTable";
import ProductTable from "./Tables/ProductTable";
import CategoryTable from "./Tables/CategoryTable";
import { CgProfile } from "react-icons/cg";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaShippingFast } from "react-icons/fa";
import { TbReportMoney } from "react-icons/tb";
import OrderTable from "./Tables/OrderTable";
import Widget from "./Widget";
import AxiosClient from "../../Helpers/axios";
import { BiCategory } from "react-icons/bi";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tabQuery = queryParams.get("tab");
  const tabMap = {
    product: 0,
    category: 1,
    user: 2,
    order: 3,
  };

  const [value, setValue] = useState(tabMap[tabQuery] || 0);
  const [statistics, setStatistics] = useState({
    revenue: 0,
    product: 0,
    user: 0,
    order: 0,
  });

  useEffect(() => {
    getProductInfo();
  }, []);

  useEffect(() => {
    setValue(tabMap[tabQuery] || 0);
  }, [tabQuery]);

  const getProductInfo = async () => {
    try {
      const { data } = await AxiosClient.get("api/admin/chartdata");
      setStatistics(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const tabName = Object.keys(tabMap).find((key) => tabMap[key] === newValue);
    navigate(`${location.pathname}?tab=${tabName}`);
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={2}
        direction={isSmallScreen ? "column" : "row"}
        padding={1}
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Widget
            numbers={statistics.revenue}
            heading="Revenue"
            color="#9932CC"
            icon={<TbReportMoney />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Widget
            numbers={statistics.product}
            heading="Products"
            color="#FFC300"
            icon={<AiOutlineShoppingCart />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Widget
            numbers={statistics.user}
            heading="Users"
            color="#FF69B4"
            icon={<CgProfile />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Widget
            numbers={statistics.order}
            heading="Orders"
            color="#1f77b4  "
            icon={<FaShippingFast />}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ overflowX: "a" }}
        >
          <Tab
            label={!isSmallScreen && "Products"}
            {...a11yProps(0)}
            iconPosition="start"
            icon={<AiOutlineShoppingCart fontSize={20} />}
          />
          <Tab
            label={!isSmallScreen && "Categories"}
            {...a11yProps(1)}
            iconPosition="start"
            icon={<BiCategory fontSize={20} />}
          />
          <Tab
            label={!isSmallScreen && "Users"}
            {...a11yProps(2)}
            iconPosition="start"
            icon={<CgProfile fontSize={20} />}
          />
          <Tab
            label={!isSmallScreen && "Orders"}
            {...a11yProps(3)}
            iconPosition="start"
            icon={<FaShippingFast fontSize={20} />}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProductTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CategoryTable />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserTable />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OrderTable />
      </TabPanel>
    </Box>
  );
}
