import "./login.css";
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdLockOutline } from "react-icons/md";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";

import CopyRight from "../../Components/CopyRight/CopyRight";

const Login = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    let auth = localStorage.getItem("Authorization");
    if (auth) {
      localStorage.removeItem("Authorization");
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    try {
      if (!credentials.email && !credentials.password) {
        toast.error("Email va parolni kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (!emailRegex.test(credentials.email)) {
        toast.error("To'g'ri email manzil kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (credentials.email && credentials.password) {
        const sendAuth = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/auth/login`,
          { email: credentials.email, password: credentials.password }
        );
        const receive = await sendAuth.data;
        if (receive.success === true) {
          toast.success("Sahifaga kirildi", {
            autoClose: 500,
            theme: "colored",
          });
          localStorage.setItem("Authorization", receive.authToken);
          navigate("/");
        } else {
          toast.error("Xatolik ro'y berdi, qayta urinib ko'ring", {
            autoClose: 500,
            theme: "colored",
          });
          navigate("/");
        }
      }
    } catch (error) {
      error.response.data.error.length === 1
        ? toast.error(error.response.data.error[0].msg, {
            autoClose: 500,
            theme: "colored",
          })
        : toast.error(error.response.data.error, {
            autoClose: 500,
            theme: "colored",
          });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#1976d2" }}>
          <MdLockOutline />
        </Avatar>
        <Typography component="h1" variant="h5">
          Kirish
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            value={credentials.email}
            name="email"
            onChange={handleOnChange}
            autoComplete="email"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            value={credentials.password}
            name="password"
            onChange={handleOnChange}
            label="Parol"
            type={showPassword ? "text" : "password"}
            id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleClickShowPassword}
                  sx={{ cursor: "pointer" }}
                >
                  {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                </InputAdornment>
              ),
            }}
            autoComplete="current-password"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Kirish
          </Button>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Link
                to="/forgotpassword"
                variant="body2"
                style={{ color: "#1976d2" }}
              >
                Parolni unitdingizmi?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/register" variant="body2">
                <span style={{ color: "#1976d2" }}>Ro'yhatdan o'ting</span>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CopyRight sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default Login;
