import "../Login/login.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { MdLockOutline } from "react-icons/md";
import { Box, Container } from "@mui/system";
import { toast } from "react-toastify";
import CopyRight from "../../Components/CopyRight/CopyRight";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import AxiosClient from "../../Helpers/axios";

const Register = () => {
  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const handleOnChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    let auth = localStorage.getItem("Authorization");
    if (auth) {
      localStorage.removeItem("Authorization");
      navigate("/");
    }
  }, []);

  const handleSubmit = async (e) => {
    console.log("Ro'yhat");
    e.preventDefault();
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    try {
      if (
        !credentials.email &&
        !credentials.firstName &&
        !credentials.password &&
        !credentials.phoneNumber
      ) {
        toast.error("Ma'lumotlarni to'ldiring", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (credentials.firstName.length < 1) {
        toast.error("Ismingizni kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (credentials.phoneNumber.length < 1) {
        toast.error("Telefon raqamingizni kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (emailRegex.test(credentials.email) === false) {
        toast.error("To'g'ri email kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (credentials.password.length < 5) {
        toast.error("Parol kamida 5 belgidan iborat bo'lish kerak", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (
        credentials.email &&
        credentials.firstName &&
        credentials.password
      ) {
        const sendAuth = await AxiosClient.post(`api/auth/register`, {
          firstName: credentials.firstName,
          lastName: credentials.lastName,
          email: credentials.email,
          phoneNumber: credentials.phoneNumber,
          password: credentials.password,
        });
        const receive = await sendAuth.data;
        if (receive.success === true) {
          toast.success("Ro'yhatdan o'tildi", {
            autoClose: 500,
            theme: "colored",
          });
          localStorage.setItem("Authorization", receive.authToken);
          navigate("/");
        } else {
          toast.error("Xatolik berdi, qayta urinib ko'ring", {
            autoClose: 500,
            theme: "colored",
          });
          navigate("/");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error, {
        autoClose: 500,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ marginBottom: 10 }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#1976d2" }}>
            <MdLockOutline />
          </Avatar>
          <Typography component="h1" variant="h5">
            Ro'yhatdan o'tish
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  value={credentials.firstName}
                  onChange={handleOnChange}
                  required
                  fullWidth
                  id="firstName"
                  label="Ism"
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Familiya"
                  name="lastName"
                  value={credentials.lastName}
                  onChange={handleOnChange}
                  autoComplete="family-name"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phoneNumber"
                  required
                  label="Telefon"
                  name="phoneNumber"
                  value={credentials.phoneNumber}
                  onChange={handleOnChange}
                  inputMode="numeric"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={credentials.email}
                  onChange={handleOnChange}
                  autoComplete="email"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Parol"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleClickShowPassword}
                        sx={{ cursor: "pointer" }}
                      >
                        {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                      </InputAdornment>
                    ),
                  }}
                  value={credentials.password}
                  onChange={handleOnChange}
                  autoComplete="new-password"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Ro'yhatdan o'tish
            </Button>
            <Grid container justifyContent="flex-center">
              <Grid item>
                <Link to="/login" style={{ color: "#1976d2", marginLeft: 3 }}>
                  Sahifaga kirish
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <CopyRight sx={{ mt: 5 }} />
      </Container>
    </>
  );
};

export default Register;
