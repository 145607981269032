import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Container,
  TextField,
  TableRow,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import { MdInfo } from "react-icons/md";
import AxiosClient from "../../../Helpers/axios";
import formatDate from "../../../Helpers/date";
import { AiOutlineSearch } from "react-icons/ai";

const OrderTable = () => {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 100,
      align: "center",
    },
    {
      id: "phone",
      label: "Phone Number",
      align: "center",
      minWidth: 100,
    },
    {
      id: "date",
      label: "Created On",
      minWidth: 100,
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 70,
      align: "center",
    },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
      align: "center",
    },
  ];

  const fetchOrders = async () => {
    const { data } = await AxiosClient.get("api/admin/orders", {
      params: {
        ...(searchQuery && {
          search: searchQuery,
        }),
        ...(status && { status }),
      },
    });

    setOrders(data.orders);
  };

  useEffect(() => {
    fetchOrders();
  }, [searchQuery, status]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          onChange={handleSearchInputChange}
          className="placeholder-animation"
          sx={{ width: { xs: 350, sm: 500, md: 800 } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
        <FormControl sx={{ minWidth: 120, marginLeft: 2 }}>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={status}
            label="Status"
            onChange={handleStatusChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="PENDING">PENDING</MenuItem>
            <MenuItem value="PAID">PAID</MenuItem>
            <MenuItem value="DELIVERED">DELIVERED</MenuItem>
            <MenuItem value="CANCELLED">CANCELLED</MenuItem>
          </Select>
        </FormControl>
      </Container>
      <Paper style={{ overflow: "auto" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "400px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4> Orders not found.</h4>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                orders.map((info) => (
                  <TableRow key={info._id}>
                    <TableCell component="th" scope="row">
                      {info.fullname}
                    </TableCell>
                    <TableCell>{info.phoneNumber}</TableCell>
                    <TableCell>{formatDate(info.createdAt)}</TableCell>
                    <TableCell>{info.status}</TableCell>
                    <TableCell align="center">
                      <Link to={`/admin/home/order/${info._id}`}>
                        <MdInfo size={30} />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default OrderTable;
