import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ProductCarousel = ({ products }) => {
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3, itemsFit: "contain" },
  };

  const items = products.map((item) => (
    <Zoom>
      <div className="item" style={{ marginTop: 10 }}>
        <img
          src={item.url}
          loading="lazy"
          alt={item.position}
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </div>
    </Zoom>
  ));

  return (
    <AliceCarousel
      animationType="fadeout"
      disableButtonsControls
      animationDuration={800}
      items={items}
      autoPlayInterval={2500}
      responsive={responsive}
    />
  );
};

export default ProductCarousel;
