import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const CopyRight = (props) => {
  return (
    <Link to="/">
      <Typography
        variant="body1"
        fontWeight="bold"
        color="text.secondary"
        align="center"
        {...props}
        style={{ color: "#1976d2" }}
      >
        {" "}
        © {new Date().getFullYear()} - LuxBags
      </Typography>
    </Link>
  );
};

export default CopyRight;
