import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { MdInfo } from "react-icons/md";
import AxiosClient from "../../../Helpers/axios";
import formatDate from "../../../Helpers/date";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 100,
      align: "center",
    },
    {
      id: "phone",
      label: "Phone Number",
      align: "center",
      minWidth: 100,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 70,
      align: "center",
    },
    {
      id: "date",
      label: "Created On",
      minWidth: 100,
      align: "center",
    },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
      align: "center",
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchProducts = async () => {
    const { data } = await AxiosClient.get("api/admin/users", {
      params: {
        ...(searchQuery && {
          search: searchQuery,
        }),
      },
    });

    setUsers(data.users);
  };

  useEffect(() => {
    fetchProducts();
  }, [searchQuery]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          onChange={handleSearchInputChange}
          className="placeholder-animation"
          sx={{ width: { xs: 350, sm: 500, md: 800 } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <Paper style={{ overflow: "auto" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "400px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4> User not found.</h4>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                users.map((info) => (
                  <TableRow key={info._id}>
                    <TableCell component="th" scope="row">
                      {info.firstName + " " + info.lastName}
                    </TableCell>
                    <TableCell>{info.phoneNumber}</TableCell>
                    <TableCell>{info.email}</TableCell>
                    <TableCell>{formatDate(info.createdAt)}</TableCell>
                    <TableCell align="center">
                      <Link to={`user/${info._id}`}>
                        <MdInfo size={30} />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default UserTable;
