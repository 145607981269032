import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container } from "@mui/material";
import BasicTabs from "../Components/AdminTabs";
import CopyRight from "../../Components/CopyRight/CopyRight";
import axios from "axios";

const AdminHomePage = () => {
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    getUser();
  }, []);
  let navigate = useNavigate();

  const getUser = async () => {
    try {
      const authToken = localStorage.getItem("Authorization");
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/auth/getuser`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );

      if (data.role === "ADMIN_USER") {
        setAdmin(true);
      } else {
        toast.error("Incorrect user role", {
          autoClose: 500,
          theme: "colored",
        });
        navigate("/");
      }
    } catch (error) {
      !isAdmin && navigate("/login");
      console.error(error);
    }
  };
  return (
    <>
      {isAdmin && (
        <Container maxWidth="100%">
          <h1
            style={{ textAlign: "center", margin: "20px 0", color: "#1976d2" }}
          >
            Dashboard{" "}
          </h1>
          <BasicTabs />
        </Container>
      )}
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default AdminHomePage;
