import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { AiFillCloseCircle, AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { Transition } from "../../Constants/Constant";
import CopyRight from "../../Components/CopyRight/CopyRight";
import { IoMdSave } from "react-icons/io";
import AxiosClient from "../../Helpers/axios";
import { IoCloudUpload } from "react-icons/io5";

const SingleCategory = () => {
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

  const [thumbnailId, setThumbnailId] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");

  const [categoryInfo, setCategoryInfo] = useState({
    name: "",
  });

  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getSingleCategory();
    window.scroll(0, 0);
  }, []);

  const getSingleCategory = async () => {
    const { data } = await AxiosClient.get(`api/category/${id}`);

    setCategoryInfo({
      name: data.name,
    });
    setThumbnailId(data.image);
    setThumbnailUrl(data.url);
    setCategoryTitle(data.name);

    setLoading(false);
  };
  const handleOnchange = (e) => {
    setCategoryInfo({ ...categoryInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!categoryInfo.name || !thumbnailId) {
      toast.error("All fields are required", { autoClose: 500 });
    }

    try {
      await AxiosClient.put(`api/admin/category/${id}`, {
        name: categoryInfo.name,
        image: thumbnailId,
      });
      toast.success("Category updated successfully", { autoClose: 500 });
      navigate("/admin/home?tab=category");
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 500 });
    }
  };

  const deleteCategory = async () => {
    try {
      await AxiosClient.delete(`api/admin/category/${id}`);

      toast.success("Category deleted successfully", {
        autoClose: 500,
        theme: "colored",
      });
      navigate("/admin/home?tab=category");
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 500, theme: "colored" });
    }
  };

  const handleFileUpload = async (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileType", "category");

    try {
      const { data } = await AxiosClient.post("api/file", formData);
      setThumbnailId(data.fileKey);
      toast.success("Picture uploaded successfully", {
        autoClose: 500,
        theme: "colored",
      });
      setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      toast.error("Picture upload failed", {
        autoClose: 500,
        theme: "colored",
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <Container sx={{ width: "100%", marginBottom: 5 }}>
        <div>
          <Typography variant="h4">
            {categoryTitle}{" "}
            <Button
              variant="contained"
              color="error"
              endIcon={<AiFillDelete />}
              onClick={() => setOpenAlert(true)}
            >
              Delete
            </Button>
          </Typography>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ marginTop: 30 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                value={categoryInfo.name}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          {loading ? (
            <section
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rectangular" height={200} width="200px" />
              <Skeleton variant="text" height={400} width={700} />
            </section>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-around",
                height: "450px",
              }}
            >
              <div className="detail-img-box">
                <img
                  alt={categoryInfo.name}
                  src={thumbnailUrl}
                  className="detail-img"
                />
              </div>
              <Button
                variant="contained"
                component="label"
                disabled={uploading}
                sx={{ marginTop: "5px" }}
              >
                {uploading ? "Uploading..." : "Update picture "}
                <IoCloudUpload />
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
            </Box>
          )}
          <Container
            sx={{
              textAlign: "right",
              marginTop: 10,
            }}
          >
            <Button variant="contained" endIcon={<IoMdSave />} type="submit">
              Save
            </Button>
          </Container>
        </form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "25px 0",
            width: "100%",
          }}
        ></Box>
        <Dialog
          open={openAlert}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenAlert(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 } }}>
            <DialogContentText
              style={{ textAlign: "center" }}
              id="alert-dialog-slide-description"
            >
              <Typography variant="body1">
                Do you want to delete category?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAlert(false)}
              endIcon={<AiFillCloseCircle />}
            >
              Close
            </Button>
            <Button
              variant="contained"
              endIcon={<AiFillDelete />}
              color="error"
              onClick={deleteCategory}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default SingleCategory;
