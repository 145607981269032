import "./Mobile.css";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import {
  AiOutlineHome,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "@mui/material";
import { ContextFunction } from "../Context/Context";
import { getCart, getWishList } from "../Constants/Constant";
import { TbTruckDelivery } from "react-icons/tb";

const MobileNavigation = () => {
  const { cart, setCart, wishlistData, setWishlistData } =
    useContext(ContextFunction);

  let authToken = localStorage.getItem("Authorization");
  let setProceed = authToken !== null ? true : false;

  useEffect(() => {
    getCart(setProceed, setCart, authToken);
    getWishList(setProceed, setWishlistData, authToken);
  }, []);

  return (
    <Box className="showMobile">
      <BottomNavigation
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          position: "fixed",
          bottom: 0,
          overflowX: "hidden",
          height: 60,
          background: "white",
        }}
      >
        <NavLink
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="links">
            <AiOutlineHome style={{ fontSize: 23 }} />
          </div>
        </NavLink>
        <NavLink
          to="/cart"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="links">
            <Badge badgeContent={setProceed ? cart.length : 0}>
              <AiOutlineShoppingCart style={{ fontSize: 23 }} />
            </Badge>
          </div>
        </NavLink>
        <NavLink
          to="/wishlist"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="links">
            <Badge badgeContent={setProceed ? wishlistData.length : 0}>
              <AiOutlineHeart style={{ fontSize: 23 }} />
            </Badge>
          </div>
        </NavLink>
        <NavLink
          to="/order"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="links">
            <Badge>
              <TbTruckDelivery style={{ fontSize: 23 }} />
            </Badge>
          </div>
        </NavLink>

        <NavLink
          to="/profile"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="links">
            <CgProfile style={{ fontSize: 23 }} />
          </div>
        </NavLink>
      </BottomNavigation>
    </Box>
  );
};

export default MobileNavigation;
