import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  CardMedia,
  IconButton,
} from "@mui/material";
import { AiFillCloseCircle, AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { Transition } from "../../Constants/Constant";
import CopyRight from "../../Components/CopyRight/CopyRight";
import { IoMdSave } from "react-icons/io";
import AxiosClient from "../../Helpers/axios";
import { IoCloudUpload } from "react-icons/io5";
import { MdDelete } from "react-icons/md";

const SingleProduct = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  const [thumbnailId, setThumbnailId] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState("");

  const [productInfo, setProductInfo] = useState({
    name: "",
    price: "",
    category: "",
    description: "",
  });

  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryUrls, setGalleryUrls] = useState([]);

  const { id } = useParams();
  let navigate = useNavigate();

  const fetchCategories = async () => {
    const { data } = await AxiosClient.get("api/category");

    setCategories(data.categories);
  };

  useEffect(() => {
    getSingleProduct();
    fetchCategories();
    window.scroll(0, 0);
  }, []);

  const getSingleProduct = async () => {
    const { data } = await AxiosClient.get(`api/product/fetchproduct/${id}`);

    setProductInfo({
      name: data.name,
      category: data.categoryId,
      description: data.description,
      price: data.price,
    });
    setTitle(data.name);
    setThumbnailId(data.thumbnail);
    setThumbnailUrl(data.url);

    // Assuming data.images is an array of image objects with fileKey and url properties
    const gallery = data.images.map((image, index) => ({
      fileKey: image.fileKey,
      url: image.url,
      position: index + 1,
    }));
    setGalleryImages(gallery);
    setGalleryUrls(gallery.map((image) => image.url));

    setLoading(false);
  };

  const handleOnchange = (e) => {
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !productInfo.name ||
      !thumbnailId ||
      !productInfo.price ||
      !productInfo.category ||
      !productInfo.description
    ) {
      toast.error("All fields are required", { autoClose: 500 });
      return;
    }

    try {
      await AxiosClient.put(`api/admin/updateproduct/${id}`, {
        name: productInfo.name,
        price: productInfo.price,
        description: productInfo.description,
        categoryId: productInfo.category,
        thumbnail: thumbnailId,
        images: galleryImages,
      });
      toast.success("Product updated successfully", { autoClose: 500 });
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 500 });
    }
  };

  const deleteProduct = async () => {
    try {
      await AxiosClient.delete(`api/admin/deleteproduct/${id}`);

      toast.success("Product deleted successfully", {
        autoClose: 500,
        theme: "colored",
      });
      navigate("/admin/home");
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 500, theme: "colored" });
    }
  };

  const handleFileUpload = async (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileType", "products");

    try {
      const { data } = await AxiosClient.post("api/file", formData);
      setThumbnailId(data.fileKey);
      toast.success("Picture uploaded successfully", {
        autoClose: 500,
        theme: "colored",
      });
      setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    } catch (error) {
      toast.error("Picture upload failed", {
        autoClose: 500,
        theme: "colored",
      });
    } finally {
      setUploading(false);
    }
  };

  const handleGalleryUpload = async (e) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("fileType", "products");

    try {
      const { data } = await AxiosClient.post("api/file", formData);
      const newImage = {
        fileKey: data.fileKey,
        position: galleryImages.length + 1,
      };
      setGalleryImages([...galleryImages, newImage]);
      setGalleryUrls([...galleryUrls, URL.createObjectURL(e.target.files[0])]);
      toast.success("Picture uploaded successfully", {
        autoClose: 500,
        theme: "colored",
      });
    } catch (error) {
      toast.error("Picture upload failed", {
        autoClose: 500,
        theme: "colored",
      });
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const newGalleryImages = [...galleryImages];
    const newGalleryUrls = [...galleryUrls];
    newGalleryImages.splice(index, 1);
    newGalleryUrls.splice(index, 1);
    setGalleryImages(newGalleryImages);
    setGalleryUrls(newGalleryUrls);
  };

  return (
    <>
      <Container sx={{ width: "100%", marginBottom: 5 }}>
        <div>
          <Typography variant="h4">
            {title}{" "}
            <Button
              variant="contained"
              color="error"
              endIcon={<AiFillDelete />}
              onClick={() => setOpenAlert(true)}
            >
              Delete
            </Button>
          </Typography>
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ marginTop: 30 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                value={productInfo.name}
                onChange={handleOnchange}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Price"
                name="price"
                value={productInfo.price}
                onChange={handleOnchange}
                variant="outlined"
                inputMode="numeric"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={productInfo.category}
                  label="Category"
                  name="category"
                  onChange={handleOnchange}
                >
                  {categories.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ margin: "10px auto" }}>
              <TextField
                id="filled-textarea"
                value={productInfo.description}
                onChange={handleOnchange}
                label="Description"
                multiline
                minRows={5}
                sx={{ width: "100%" }}
                variant="outlined"
                name="description"
              />
            </Grid>
          </Grid>
          {loading ? (
            <section
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rectangular" height={200} width="200px" />
              <Skeleton variant="text" height={400} width={700} />
            </section>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-around",
                  height: "450px",
                }}
              >
                <div className="detail-img-box">
                  <img
                    alt={productInfo.name}
                    src={thumbnailUrl}
                    className="detail-img"
                  />
                </div>
                <Button
                  variant="contained"
                  component="label"
                  disabled={uploading}
                  sx={{ marginTop: "5px" }}
                >
                  {uploading ? "Uploading..." : "Update main picture"}
                  <IoCloudUpload />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  disabled={uploading}
                  sx={{ marginTop: "30px" }}
                >
                  {uploading ? "Uploading..." : "Upload Gallery Image"}
                  <IoCloudUpload />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleGalleryUpload}
                  />
                </Button>
              </Box>
              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                {galleryUrls.map((url, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={url}
                        alt={`Gallery Image ${index + 1}`}
                        sx={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                          marginTop: "10px",
                        }}
                      />
                      <IconButton
                        onClick={() => handleRemoveImage(index)}
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          color: "white",
                          backgroundColor: "rgba(0,0,0,0.5)",
                        }}
                      >
                        <MdDelete />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Container
            sx={{
              textAlign: "right",
              marginTop: 10,
            }}
          >
            <Button variant="contained" endIcon={<IoMdSave />} type="submit">
              Save
            </Button>
          </Container>
        </form>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            margin: "25px 0",
            width: "100%",
          }}
        ></Box>
        <Dialog
          open={openAlert}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenAlert(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 } }}>
            <DialogContentText
              style={{ textAlign: "center" }}
              id="alert-dialog-slide-description"
            >
              <Typography variant="body1">
                Do you want to delete product?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAlert(false)}
              endIcon={<AiFillCloseCircle />}
            >
              Close
            </Button>
            <Button
              variant="contained"
              endIcon={<AiFillDelete />}
              color="error"
              onClick={deleteProduct}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default SingleProduct;
