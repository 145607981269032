import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineFileDone } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Update.module.css";
import { toast } from "react-toastify";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import CopyRight from "../../Components/CopyRight/CopyRight";
import AxiosClient from "../../Helpers/axios";
import { FiLogOut } from "react-icons/fi";
import {
  handleClickOpen,
  handleClose,
  handleLogOut,
  Transition,
} from "../../Constants/Constant";
import { AiFillCloseCircle, AiOutlineShop } from "react-icons/ai";

const UpdateDetails = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const [userData, setUserData] = useState([]);
  let authToken = localStorage.getItem("Authorization");
  let setProceed = authToken ? true : false;

  const [isAdmin, setIsAdmin] = useState(false);

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    region: "",
  });
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  let navigate = useNavigate();
  useEffect(() => {
    setProceed ? getUserData() : navigate("/login");
  }, []);
  const getUserData = async () => {
    try {
      const { data } = await AxiosClient.get("api/auth/getuser");
      setUserDetails({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: data.address,
        city: data.city,
        region: data.region,
      });
      if (data.role === "ADMIN_USER") {
        setIsAdmin(true);
      }
      setUserData(data);
    } catch (error) {
      toast.error("Something went wrong", { autoClose: 500, theme: "colored" });
    }
  };
  const handleOnchange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!userDetails.email && !userDetails.firstName) {
        toast.error("Ism va Emailni kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (userDetails.firstName.length === 0) {
        toast.error("Ismingizni kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (userDetails.phoneNumber.length === 0) {
        toast.error("Telefon raqamingizni kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (!emailRegex.test(userDetails.email)) {
        toast.error("To'g'ri email kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else {
        await AxiosClient.put("api/auth/updateuser", {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          phoneNumber: userDetails.phoneNumber,
          email: userDetails.email,
          address: userDetails.address,
          city: userDetails.city,
          region: userDetails.region,
        });
        toast.success("Saqlandi", {
          autoClose: 500,
          theme: "colored",
        });
        getUserData();
      }
    } catch (error) {
      let message = error.response.data.message;
      console.error(error);
      if (message === "Email exists") {
        message = "Email boshqa foydalanuvchi tomonidan foydalanilgan";
      }
      toast.error(message, {
        autoClose: 500,
        theme: "colored",
      });
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (!password.currentPassword && !password.newPassword) {
        toast.error("Ma'lumotlarni kiriting", {
          autoClose: 500,
          theme: "colored",
        });
      } else if (password.newPassword.length < 5) {
        toast.error("Parol kamida 5 belgi bo'lish kerak", {
          autoClose: 500,
          theme: "colored",
        });
      } else {
        await AxiosClient.post("api/password/reset/password", {
          id: userData._id,
          currentPassword: password.currentPassword,
          newPassword: password.newPassword,
        });
        toast.success("Parol yangilandi", { autoClose: 500, theme: "colored" });
        setPassword({
          currentPassword: "",
          newPassword: "",
        });
      }
    } catch (error) {
      toast.error(error.response.data, { autoClose: 500, theme: "colored" });
      console.error(error);
    }
  };

  return (
    <>
      {setProceed && (
        <Container
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: 10,
          }}
        >
          {isAdmin && (
            <Container sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                endIcon={<AiOutlineShop />}
                color="warning"
                onClick={() => navigate("/admin/home")}
              >
                Admin panel
              </Button>
            </Container>
          )}
          <Typography
            variant="h6"
            sx={{ margin: "30px 0", fontWeight: "bold", color: "#1976d2" }}
          >
            Shaxsiy ma'lumotlar
          </Typography>

          <form
            noValidate
            autoComplete="off"
            className={styles.checkout_form}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Ism"
                  name="firstName"
                  value={userDetails.firstName || ""}
                  onChange={handleOnchange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Familiya"
                  name="lastName"
                  value={userDetails.lastName || ""}
                  onChange={handleOnchange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Telefon"
                  type="tel"
                  required
                  name="phoneNumber"
                  value={userDetails.phoneNumber || ""}
                  onChange={handleOnchange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  required
                  value={userDetails.email || ""}
                  onChange={handleOnchange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Manzil: mahalla, ko'cha nomi, uy raqami"
                  name="address"
                  value={userDetails.address || ""}
                  onChange={handleOnchange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Shahar/Tuman"
                  name="city"
                  value={userDetails.city || ""}
                  onChange={handleOnchange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Viloyat"
                  name="region"
                  value={userDetails.region || ""}
                  onChange={handleOnchange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Container
              sx={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: 5,
              }}
            >
              <Button
                variant="contained"
                endIcon={<AiOutlineFileDone />}
                type="submit"
              >
                Saqlash
              </Button>
            </Container>
          </form>

          <Typography
            variant="h6"
            sx={{ margin: "20px 0", fontWeight: "bold", color: "#1976d2" }}
          >
            Parolni yangilash
          </Typography>
          <form onSubmit={handleResetPassword}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Eski parol"
                  name="currentPassword"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleClickShowPassword}
                        sx={{ cursor: "pointer" }}
                      >
                        {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                      </InputAdornment>
                    ),
                  }}
                  value={password.currentPassword || ""}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      [e.target.name]: e.target.value,
                    })
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Yangi parol"
                  name="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        sx={{ cursor: "pointer" }}
                      >
                        {showNewPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                      </InputAdornment>
                    ),
                  }}
                  value={password.newPassword || ""}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      [e.target.name]: e.target.value,
                    })
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "25px 0",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<RiLockPasswordLine />}
                type="submit"
              >
                O'zgartirish
              </Button>
            </Box>
          </form>
          <Typography
            variant="h6"
            sx={{ margin: "20px 0", fontWeight: "bold", color: "#1976d2" }}
          >
            Sahifadan chiqish
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="nav-icon-span"
            sx={{ marginBottom: 1 }}
            endIcon={<FiLogOut />}
            onClick={() => handleClickOpen(setOpenAlert)}
          >
            <Typography variant="button">Chiqish</Typography>
          </Button>
        </Container>
      )}
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            width: { xs: 280, md: 350, xl: 400 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">
            Siz rostdan ham sahifangizdan chiqmoqchimisiz?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Link to="/">
            <Button
              variant="contained"
              endIcon={<FiLogOut />}
              color="primary"
              onClick={() =>
                handleLogOut(setProceed, toast, navigate, setOpenAlert)
              }
            >
              Chiqish
            </Button>
          </Link>
          <Button
            variant="contained"
            color="error"
            endIcon={<AiFillCloseCircle />}
            onClick={() => handleClose(setOpenAlert)}
          >
            Ortga
          </Button>
        </DialogActions>
      </Dialog>

      <CopyRight sx={{ mt: 4, mb: 10 }} />
    </>
  );
};

export default UpdateDetails;
