import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import formatDate from "../../../Helpers/date";

const orderMapping = {
  PENDING: "Buyurtma kelib tushgan",
  PAID: "To'landi",
  DELIVERED: "Yetkazildi",
  CANCELLED: "Bekor qilindi",
};

const OrderCard = ({ order }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: { xs: 1, sm: 2 },
        width: "100%",
      }}
    >
      <Typography variant="body1">
        Buyurtma holati: <b>{orderMapping[order.status]}</b>
      </Typography>
      <Typography variant="body1">Kimga: {order.fullname}</Typography>
      <Typography variant="body1">Telefon: {order.phoneNumber}</Typography>
      <Typography variant="body1">Manzil: {order.location}</Typography>
      <Typography variant="body1">
        To'lov usuli: Online pul o'tkazish (Uzcard, Humo)
      </Typography>
      <Typography variant="body1">
        Yaratildi: {formatDate(order.createdAt)}
      </Typography>
      <Typography variant="body1">
        Umumiy: {order.totalPrice.toLocaleString()} so'm
      </Typography>
      <Box>
        <Typography variant="body1">Mahsulotlar</Typography>
        {order.products.map((product, index) => (
          <Box key={product._id} sx={{ marginLeft: 2 }}>
            <Typography variant="body2">
              {index + 1}. {product.productName} -{" "}
              {(product.price * product.quantity).toLocaleString()} so'm,{" "}
              {product.quantity} dona
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default OrderCard;
