import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  InputAdornment,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import AddProduct from "../AddProduct";
import AxiosClient from "../../../Helpers/axios";
import { MdInfo } from "react-icons/md";
import formatDate from "../../../Helpers/date";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";

const ProductTable = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const limit = 10;
  const page = parseInt(searchParams.get("page")) || 1;

  const columns = [
    { id: "name", label: "Name", minWidth: 170, align: "center" },
    { id: "category", label: "Category", minWidth: 100, align: "center" },
    { id: "image", label: "Image", minWidth: 100, align: "center" },
    { id: "price", label: "Price", minWidth: 100, align: "center" },
    { id: "createdAt", label: "Created", minWidth: 100, align: "center" },
    { id: "type", label: "Action", align: "center", minWidth: 100 },
  ];

  const fetchProducts = async () => {
    const { data } = await AxiosClient.get("api/admin/products", {
      params: {
        ...(searchTerm && { search: searchTerm }),
        page,
        limit,
      },
    });

    setProducts(data.products);
    setTotalPages(data.totalPages);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setSearchParams({ search: value, page: 1 });
  };

  const handlePageChange = (newPage) => {
    setSearchParams({ page: newPage, search: searchTerm });
  };

  useEffect(() => {
    fetchProducts();
  }, [searchTerm, page]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <TextField
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: { xs: 350, sm: 500, md: 800 } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <AddProduct fetchProducts={fetchProducts} />
      <Paper style={{ overflow: "auto", maxHeight: "500px" }}>
        <TableContainer sx={{ maxHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>Product not found</h4>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                products.map((prod) => (
                  <TableRow key={prod._id}>
                    <TableCell component="th" scope="row">
                      {prod.name.slice(0, 20)}
                    </TableCell>
                    <TableCell align="center">{prod.categoryId.name}</TableCell>
                    <TableCell align="center">
                      <img
                        src={prod.url}
                        alt={prod.name}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {prod.price.toLocaleString()}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(prod.createdAt)}
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/admin/home/product/${prod._id}`}>
                        <MdInfo size={30} />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
          startIcon={<BiSkipPrevious />}
        ></Button>
        <Box sx={{ margin: "0 10px" }}>
          Page {page} of {totalPages}
        </Box>
        <Button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
          endIcon={<BiSkipNext />}
        ></Button>
      </Box>
    </>
  );
};

export default ProductTable;
