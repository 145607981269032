import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import AxiosClient from "../../../Helpers/axios";
import { MdInfo } from "react-icons/md";
import AddCategory from "../AddCategory";

const CategoryTable = () => {
  const [categories, setCategories] = useState([]);

  const columns = [
    {
      id: "name",
      label: "Name",
      minWidth: 170,
      align: "center",
    },
    {
      id: "image",
      label: "Image",
      minWidth: 100,
      align: "center",
    },
    {
      id: "type",
      label: "Action",
      align: "center",
      minWidth: 100,
    },
  ];

  const fetchCategories = async () => {
    const { data } = await AxiosClient.get("api/category");

    setCategories(data.categories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <AddCategory refreshCategories={fetchCategories} />
      <Paper
        style={{
          overflow: "auto",
          maxHeight: "500px",
        }}
      >
        <TableContainer sx={{ maxHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ position: "sticky", top: 0 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h4>Category not found</h4>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                categories.map((prod) => (
                  <TableRow key={prod._id}>
                    <TableCell component="th" scope="row">
                      {prod.name.slice(0, 20)}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={prod.url}
                        alt={prod.name}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/admin/home/category/${prod.id}`}>
                        <MdInfo size={30} />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default CategoryTable;
