import axios from "axios";
import React from "react";
import UserInfoItem from "../Components/UserData/UserInfoItem";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import CopyRight from "../../Components/CopyRight/CopyRight";

const SingleUserPage = () => {
  const { id } = useParams();
  let authToken = localStorage.getItem("Authorization");
  const commonGetRequest = async (url, userId, setData) => {
    try {
      const newUrl = `${url}/${userId}`;
      const { data } = await axios.get(newUrl, {
        headers: {
          Authorization: authToken,
        },
      });
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Container>
        <UserInfoItem
          commonGetRequest={commonGetRequest}
          authToken={authToken}
          id={id}
        />
        {/*TODO: <UserOrderItem
          commonGetRequest={commonGetRequest}
          authToken={authToken}
          id={id}
        /> */}
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default SingleUserPage;
