function formatDate(dateValue) {
  const date = new Date(dateValue);

  // Pad single digit numbers with a leading zero
  const pad = (number) => (number < 10 ? "0" + number : number);

  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

export default formatDate;
