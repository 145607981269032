import "./singlecategory.css";
import React, { useState, useEffect } from "react";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Container } from "@mui/system";
import {
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  Typography,
  Divider,
} from "@mui/material";
import Loading from "../../Components/loading/Loading";
import { BiFilterAlt, BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import CopyRight from "../../Components/CopyRight/CopyRight";
import AxiosClient from "../../Helpers/axios";
import ProductCard from "../../Components/Card/ProductCard/ProductCard";

const SingleCategory = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("Barchasi");
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const { categoryId } = useParams();
  const nav = useNavigate();

  const page = parseInt(searchParams.get("page")) || 1;
  const limit = 10;

  useEffect(() => {
    getCategoryProduct();
    window.scroll(0, 0);
  }, [page]);

  const getCategoryProduct = async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosClient.get(
        `api/category/${categoryId}/products?page=${page}&limit=${limit}`
      );
      setIsLoading(false);
      setProducts(data.products);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) setSearchParams({ page: page + 1 });
  };

  const handlePrevPage = () => {
    if (page > 1) setSearchParams({ page: page - 1 });
  };

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  const loading = isLoading ? (
    <Container
      maxWidth="xl"
      style={{
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        paddingLeft: 10,
        paddingBottom: 20,
      }}
    >
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
      <Loading />
    </Container>
  ) : (
    ""
  );

  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          marginTop: 90,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ minWidth: 140 }}>
          <FormControl sx={{ width: 140 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                width: "80vw",
              }}
            >
              <Button endIcon={<BiFilterAlt />}>Filter</Button>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={title}
                sx={{ width: 200 }}
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="Barchasi">Barchasi</MenuItem>
              </Select>
            </Box>
          </FormControl>
        </Box>
        {loading}
        <Container
          maxWidth="xl"
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingBottom: 20,
            marginBottom: 30,
            width: "100%",
          }}
        >
          {products.length === 0 && !isLoading ? (
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Mahsulot topilmadi
              <Divider />
              <Button
                variant="contained"
                onClick={() => nav(`/`)}
                sx={{ mt: 2 }}
              >
                Barcha bo'limlar
              </Button>
            </Typography>
          ) : (
            products.map((prod) => (
              <Link to={`/product/${prod.slug}`} key={prod.id}>
                <ProductCard prod={prod} />
              </Link>
            ))
          )}
        </Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            mt: 2,
          }}
        >
          {page > 1 && (
            <Button
              variant="contained"
              onClick={handlePrevPage}
              startIcon={<BiSkipPrevious />}
            >
              Oldingi
            </Button>
          )}
          {page < totalPages && (
            <Button
              variant="contained"
              onClick={handleNextPage}
              endIcon={<BiSkipNext />}
            >
              Keyingi
            </Button>
          )}
        </Box>
      </Container>
      <CopyRight sx={{ mt: 8, mb: 10 }} />
    </>
  );
};

export default SingleCategory;
